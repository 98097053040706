.mods {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    font-size: 1rem;
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    gap: 20px 10px;
    box-sizing: border-box;
}
