.product {
    width: 90%;
    text-align: center;
}

.product img {
    width: 100%;
}

.product a {
    color: black;
    text-decoration: unset;
}

.title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.subtitle {
    margin-top: 0;
}

@media only screen and (min-width: 409px) {

    .product {
        width: 45%;
    }

}

@media only screen and (min-width: 750px) {

    .product {
        width: 32%;
    }

}

@media only screen and (min-width: 1080px) {

    .product {
        width: 23%;
    }

}
