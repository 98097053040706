.coming-soon-main {
    width: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coming-soon-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-text {
    height: 108px;
}

.coming-soon-main > h1 {
    margin-top: 6vh;
    margin-bottom: 10vh;
    font-size: 1.25em;
    font-weight: normal;
}

.coming-soon-social {
    display: flex;
    column-gap: 10vw;
}

.coming-soon-social > a {
    color: black;
    text-decoration: none;
}

@media only screen and (min-width: 750px) {
    .coming-soon-main > h1 {
        font-size: 1.75em;
    }
}
  
@media only screen and (min-width: 1080px) {
    .coming-soon-social {
        column-gap: 6vw;
    }
}
