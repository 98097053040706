.product-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    font-size: 1rem;
}

.product-image {
    width: 100%;
    margin-top: 20px;
}

.content {
    white-space: pre-wrap;
    text-align: left;
}

h1 {
    margin-bottom: 0.5rem;
}

h2 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: normal;
}

@media only screen and (min-width: 500px) {

    .product-image {
        width: 80%;
    }

}

@media only screen and (min-width: 750px) {

    .product-detail {
        flex-direction: row;
        align-items: flex-start;
    }

    .product-image {
        width: 60%;
    }

}

@media only screen and (min-width: 1080px) {

    .product-image {
        width: 40%;
    }

}
