.about {
    padding-top: 8%;
    font-size: 1rem;
}


@media only screen and (min-width: 500px) {

    .about {
        padding-left: 8%;
        padding-right: 8%;
    }

}

@media only screen and (min-width: 750px) {

    .about {
        font-size: 1.5rem;
    }

}
