body {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  padding-bottom: 110px;
}

*, *::before, *::after {
  box-sizing: inherit;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
  font-size: 1.5rem;
}

@media only screen and (min-width: 750px) {

  body {
    padding-bottom: 130px;
  }

}

@media only screen and (min-width: 1080px) {

  #body {
    padding-bottom: 160px;
  }

}
