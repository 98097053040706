header {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    border-bottom: 2px solid black;
    padding: 8px 8px 0.5rem 8px;
}

.logo {
    display: flex;
}

#logo-img {
    display: none;
}

#logo-text {
    width: 161px;
    height: 64;
}

.links {
    display: none;
}

.links > a {
    color: black;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 0 2rem;
    text-decoration: none;
}

@media only screen and (min-width: 500px) {

    header {
        height: 5rem;
    }

    #logo-img {
        display: initial;
        width: 83px;
        height: 80px;
    }

    #logo-text {
        width: 200px;
        height: 80px;
    }

}
  
@media only screen and (min-width: 1080px) {

    .links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

}
